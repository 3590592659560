<template>
  <div class="login-model">
    <el-dialog
      title=""
      :modal-append-to-body="false"
      :visible.sync="loginDialog"
      width="507px"
      :showClose="false"
      :before-close="handleClose"
    >
      <div class="card" v-show="isPhoneLogin">
        <div class="phone-login">
          <span class="header-title">手机号登录/注册</span>
        </div>
        <!--        <div class="wx-login">
          <img
              src="/imgs/to-wx-login.png"
              class="wx-img"
              @click="checkLoginWay(false)"
              alt="echat"
          >
        </div>-->
        <el-form
          :model="loginFrom"
          class="login-form"
          ref="loginFrom"
          :hide-required-asterisk="true"
          :rules="rules"
        >
          <el-form-item label="手机号" prop="phoneNumber">
            <!--  :disabled="disabled"  -->
            <el-input
              v-model="loginFrom.phoneNumber"
              name="login_name"
              autocomplete="on"
              placeholder="请输入手机号码"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="smsCode">
            <el-input v-model="loginFrom.smsCode" placeholder="请输入验证码">
              <template #suffix>
                <span v-show="getCodeButton" class="getCode" @click="getCode"
                  >获取验证码</span
                >
                <span v-show="!getCodeButton" class="getCode"
                  >{{ time }}后重试</span
                >
              </template>
            </el-input>
          </el-form-item>
          <el-button
            type="primary"
            @click="login"
            :style="{ width: '100%' }"
            class="login-form-button"
            style="margin-top: 8px"
          >
            登录/注册
          </el-button>
        </el-form>
      </div>
      <div class="wx-card" v-show="!isPhoneLogin">
        <div class="wx-login">
          <span class="header-title">微信扫码登录/注册</span>
        </div>

        <div class="phone-login">
          <img
            src="/imgs/to-phone-login.png"
            class="phone-img"
            @click="checkLoginWay(true)"
            alt=""
          />
        </div>

        <div class="text-center" style="margin-top: 64px">
          <img class="wx-img" :src="qrcodeUrl || null" alt="" />
        </div>

        <div class="text-center" style="margin-top: 12px">
          <span class="tip-login">扫码关注公众号完成登录</span>
        </div>

        <!-- <div class="text-center" style="margin-top: 32px">
          <span class="agree">登录即同意</span>
          <span class="sign-agreement" @click="toLegalNotices">《法律声明及隐私权限》</span>
          <span class="agree">和</span>
          <span class="sign-agreement" @click="toUserAgreement">《用户协议》</span>
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog } from "element-ui";
import { mapMutations } from "vuex";
import {
  code,
  getWechatQrcode,
  getWechatToken,
  login,
  getInfo,
} from "../api/login";

export default {
  name: "loginModel",
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        let patt = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (patt.test(value)) {
          callback();
        }
        callback(new Error("请输入正确的手机号码"));
      }
    };
    return {
      isPhoneLogin: true,
      loginDialog: false,
      qrcodeUrl: "",
      loginFrom: {
        phoneNumber: "",
        smsCode: "",
      },
      rules: {
        phoneNumber: [{ validator: validatePass, trigger: "change" }],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },

      getCodeButton: true,
      codeForm: {
        mobile: "",
      },
      disabled: false,
      time: 60,
      timer: null,
      wechatQrcode: {
        clientKey: process.env.VUE_APP_CLIENTKEY,
        channel: "Member",
      },
      wechatTicketForm: {
        ticket: "",
      },
      weChatAccessTokenTimer: "",
      register_source: "",
    };
  },
  mounted() {
    this.$Bus.$on("showLogin", (res) => {
      if (res) {
        this.showModel();
      }
    });

    let path = window.location.hash.split("?");
    if (path.length > 1) {
      this.register_source = window.location.hash.split("?")[1].split("=")[1];
    } else {
      this.register_source = "";
    }
  },

  methods: {
    ...mapMutations("user", ["setToken", "updateUserInfo"]),
    handleClose() {
      this.loginDialog = false;
      this.cancelWeChatTimer();
    },

    showModel() {
      this.loginDialog = true;
      if (!this.isPhoneLogin) {
        this.getWechatQrcode();
      } else {
        this.cancelWeChatTimer();
      }
    },

    // 登陆
    login() {
      this.$refs["loginFrom"].validate((valid) => {
        if (valid) {
          let params = {
            mobile: this.loginFrom.phoneNumber,
            smsCode: this.loginFrom.smsCode,
            grant_type: "sms_code",
            scope: "all",
            loginChannel: "Member",
            register_platform: "cp",
            register_source: this.register_source,
            logidUrl:sessionStorage.getItem("logidUrl")
          };
          console.log(params);
          login(params).then((res) => {
            this.loginDialog = false;
            if (res.code === 200) {
              console.log(res.data);
              localStorage.setItem("token", res.data.access_token);
              this.setToken(res.data.access_token);
              this.$message.success("登录成功");

              this.$refs["loginFrom"].resetFields();

              this.cancelWeChatTimer();
              getInfo().then((res) => {
                if (res.code === 200) {
                  this.updateUserInfo(res.data);
                  localStorage.setItem("userInfo", JSON.stringify(res.data));
                }
              });
            } else if (res.code === 500) {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    // 获取验证码
    getCode() {
      this.$refs["loginFrom"].validateField(["phoneNumber"], (err) => {
        if (!err) {
          this.getCodeButton = false;
          this.disabled = true;
          this.timer = setInterval(() => {
            this.time--;
            if (this.time <= 0) {
              this.time = 60;
              this.getCodeButton = true;
              this.disabled = false;
              clearInterval(this.timer);
            }
          }, 1000);
          this.codeForm.mobile = this.loginFrom.phoneNumber;
          code(this.codeForm).then((res) => {
            if (res.code === 200) {
              this.$message.success("短信发送成功");
            } else {
              this.$message.error("短信发送失败，请稍后再试");
            }
          });
        }
      });
    },

    // 获取场景二维码
    getWechatQrcode() {
      getWechatQrcode(this.wechatQrcode).then((res) => {
        if (res.data.ticket) {
          this.qrcodeUrl = res.data.qrcodeUrl;
          this.wechatTicketForm.ticket = res.data.ticket;
          this.weChatTimer();
        }
      });
    },

    // 定时轮询获取微信号关注信息
    weChatTimer() {
      this.weChatAccessTokenTimer = window.setInterval(() => {
        setTimeout(this.queryWeChatAccessTokenFlag, 0);
      }, 1500);
    },

    // 取消定时轮询任务
    cancelWeChatTimer() {
      clearInterval(this.weChatAccessTokenTimer);
    },

    // 是否需要轮询
    queryWeChatAccessTokenFlag() {
      if (this.wechatTicketForm.ticket) {
        this.queryWeChatAccessToken();
      }
    },

    // 获取微信公众号的信息
    queryWeChatAccessToken() {
      if (localStorage.getItem("token")) {
        localStorage.setItem("token", "");
        localStorage.setItem("userInfo", "");
        this.$store.commit("setToken", "");
        this.$store.commit("setUserInfo", "");
      }
      getWechatToken(this.wechatTicketForm).then((res) => {
        if (res.data && res.data.unionid) {
          this.cancelWeChatTimer();
          if (res.data.mobile) {
            // this.loginForm.mobile = res.data.mobile;
            // this.loginForm.smsCode = res.data.access_token;
            // this.loginForm.wechatId = res.data.unionid;
            let params = {
              mobile: res.data.mobile,
              smsCode: res.data.access_token,
              grant_type: "sms_code",
              scope: "all",
              loginChannel: "Member",
              register_platform: "cp",
              register_source: this.register_source,
            };
            login(params).then((res) => {
              this.loginDialog = false;
              if (res.code === 200) {
                this.setToken(res.data.access_token);
                localStorage.setItem("token", res.data.access_token);
                this.$emit("loginSuccess");
                // getInfo().then(res => {
                //   if(res.code === 200) {
                //     localStorage.setItem("userInfo", JSON.stringify(res.data));
                //   }
                // } )
                getInfo().then((res) => {
                  if (res.code === 200) {
                    this.updateUserInfo(res.data);
                    localStorage.setItem("userInfo", JSON.stringify(res.data));
                  }
                });
                this.$message.success("登录成功");
              } else if (res.code === 500) {
                this.$message.error(res.message);
              }
            });
          } else {
            this.$router.push({
              path: "/bind-phone",
              query: {
                wechatId: res.data.unionid,
                registerSource: this.register_source,
              },
            });
            this.$message.warning("登录成功，请绑定您的手机号");
          }
        }
      });
    },

    checkLoginWay(val) {
      val ? this.cancelWeChatTimer() : this.getWechatQrcode();
      this.isPhoneLogin = val;
    },
  },

  beforeDestroy() {
    this.cancelWeChatTimer();
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 12px;
}

.login-model {
  .card {
    box-sizing: border-box;
    margin: auto 0;
    padding: 4px 44px 64px;
    background: #ffffff;
    border-radius: 12px;

    .phone-login {
      .header-title {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 45px;
        color: #202839;
      }
    }

    .wx-login {
      float: right;
      margin-top: -101px;
      margin-right: -76px;
      cursor: pointer;

      .wx-img {
        width: 56px;
        height: 56px;
      }
    }

    .login-form {
      margin-top: 52px;

      .getCode {
        font-weight: 400;
        color: #4850ff;

        &:first-child {
          cursor: pointer;
        }
      }

      .agree {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        color: #adb6ca;
      }

      .sign-agreement {
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        color: #58637b;
      }
    }
  }
  .wx-card {
    box-sizing: border-box;
    padding: 4px 44px 69px;
    background: #ffffff;
    border-radius: 12px;

    .wx-login {
      .header-title {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 45px;
        color: #202839;
      }
    }

    .phone-login {
      float: right;
      margin-top: -101px;
      margin-right: -76px;
      cursor: pointer;
      .phone-img {
        width: 56px;
        height: 56px;
      }
    }

    .wx-img {
      width: 200px;
      height: 200px;
    }

    .tip-login {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #202839;
    }

    .text-center {
      text-align: center;
    }

    .agree {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #adb6ca;
    }

    .sign-agreement {
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #58637b;
    }
  }
  @media only screen and (max-width: 800px) {
    /deep/.el-dialog {
      width: 100% !important;
      height: auto;
    }
    /deep/ .v-modal {
      z-index: 20;
    }
    .wx-login {
      display: none;
    }
    .card {
      padding: 0;
    }
  }
}
</style>
